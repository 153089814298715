<template>
  <v-container>
    <v-row class="d-flex align-start">
      <v-col lg="9" class="my-5 pr-5">
        <h1 class="indigo--text text--lighten-1 text-center">
          UN AÑO CON EMMET FOX
        </h1>
        <div class="d-flex justify-center mb-5">
          <v-img src="../../public/emmet_fox.png" max-width="40%"></v-img>
        </div>
        <p class="mb-5 text-center">{{ efHoy.dia }} | {{ efHoy.titulo }}</p>

        <p class="text-justify" v-html="efHoy.cuerpo"></p>
      </v-col>
      <v-col lg="3" class="pl-2"><PanelDerecho /></v-col>
    </v-row>
  </v-container>
</template>
<script>

import PanelDerecho from "../components/Inicio/PanelDerecho";
export default {
  name: "UnAnoConEmmetFox",
  components: {
    PanelDerecho,
  },
  computed: {
    efHoy() {
      const emmetFoxHoy = this.$route.params.id;
      return this[emmetFoxHoy];
    },
  },
  data() {
    return {
      febrero9: {
        dia: "9 DE FEBRERO",
        titulo: "EL PAN NUESTRO DE CADA DÍA",
        cuerpo: "<p><i>El pan nuestro de cada día, dánoslo hoy.</i> (Mateo 6:11).</p><p>Ya que somos los hijos de un Padre amoroso, tenemos derecho a esperar que Dios nos provea con todo lo que necesitamos. Si esperamos con fe y entendimiento, nunca esperaremos en vano.</p><p>La voluntad de Dios es que tengamos vidas sanas y felices, llenas de gozosas experiencias; que nos desarrollemos libre y firmemente. Para lograr este propósito necesitamos comida, vestimenta, refugio, transporte, libros, etc.  Sobre todo, necesitamos libertad. En la oración, todas esas cosas están incluidas bajo el nombre de “pan”, ya que este no solo se refiere a la comida en general, sino todas las cosas necesarias para una vida sana, libre y armoniosa. Para obtener esas cosas, tenemos que reclamarlas y reconocer a Dios en sí mismo, como el único origen y la única fuente de todo nuestro bien.  Siempre que rastreemos el origen de cualquier tipo de escasez, encontraremos que había estado buscando el suministro en una fuente secundaria, en vez de en Dios mismo, el autor y dador de vida.</p>"
      },
      febrero10: {
        dia: "10 DE FEBRERO",
        titulo: "EL DADOR DE NUESTRO PAN",
        cuerpo: "<p>La gente piensa que su suministro viene de ciertas inversiones, o de un negocio, o quizás de su patrón.  Pero estos son simplemente los canales a través de los cuales viene, siendo Dios la fuente. Es probable que un canal particular cambie, ya que el cambio es la ley cósmica para la manifestación.  El estancamiento es realmente muerte; pero mientras te des cuenta de que la fuente de tu suministro es el mismo invariable Espíritu, todo estará bien. La desaparición de un canal será solo la señal de la apertura de otro.</p><p>En su interno y más importante significado, nuestro pan diario significa la realización de la Presencia de Dios –un sentido real de que Dios existe no simplemente en una forma nominal, sino como la gran realidad-.  Podemos contar con Él para que nos suministre todo lo que necesitamos tener; para que nos enseñe todo lo que necesitamos saber; y guíe nuestros pasos de forma tal que no cometamos errores. Este en Emmanuel, o Dios con nosotros.</p><p><i>Mi Dios, pues suplirá todo lo que es falta…</i> (Filipenses 4:10).</p>"
      },
      febrero11: {
        dia: "11 DE FEBRERO",
        titulo: "DEMANDANDO NUESTRO PAN DIARIO",
        cuerpo: "<p>El error común, por supuesto, es suponer que un reconocimiento formal de Dios es suficiente, o que hablar acerca de las cosas divinas es lo mismo que poseerlas. Pero eso es exactamente igual a suponer que mirar una bandeja de comida, o discutir la composición química de diversos comestibles, es lo mismo que comer en sí. Este error es el responsable de que las personas algunas veces oren por una cosa por años sin ver ningún resultado tangible. Si la oración es una fuerza, de ninguna manera puede ser posible orar sin que nada pase. Ora regular y tranquilamente –recuerda que en todo trabajo mental, el esfuerzo o la tensión  se derrota a sí mismo-, luego vendrá la realización.</p><p>Otra razón de por qué el símbolo del pan para la experiencia de la Presencia de Dios es tan convincente, es que el acto de comer es esencialmente una cosa que debe ser hecha por uno mismo. Nadie puede asimilar comida de otro.  De la misma forma, la realización de la Presencia de Dios es una cosa que ninguna persona puede hacer por nosotros.</p><p><i>Porque sacia al alma menesterosa y llena de bien al alma hambrienta.</i> (Salmo 107:9).</p>"
      },
      febrero12: {
        dia: "12 DE FEBRERO",
        titulo: "NUESTRO PAN DE CADA DÍA",
        cuerpo: "<p>Hablando del “Pan de Vida”, Jesús lo llamó nuestro pan de cada día. La razón para esto es bien fundamental: nuestro contacto con Dios tiene que ser algo vivo.  Es  nuestra actitud momentánea la que gobierna nuestro ser.</p><p><i>…he aquí ahora el tiempo, aceptadle; he aquí ahora el día de la salvación.</i> (Corintios 6:2).</p><p>La cosa más fútil en el mundo es tratar de vivir sobre una realización pasada. El significado de la vida espiritual para ti es tu realización de Dios aquí y ahora.</p><p>Sé agradecido por la experiencia de ayer, sabiendo que estará contigo para siempre en el cambio de consciencia que causó, pero no te apoyes sobre eso ni un solo momento para la necesidad de hoy. El maná en el desierto es el prototipo del Antiguo Testamento de este alimento diario. A la gente nómada les fue revelado que se les suministraría maná del cielo todo los días, pero que de ninguna manera lo debían guardar para el día siguiente. Cuando a pesar de la regla, algunos trataron de vivir con el alimento del día anterior, el resultado fue peste o muerte.</p><p>Igual sucede con nosotros. El arte de la vida es vivir en el presente y hacer ese momento tan perfecto como podamos, realizando que somos los instrumentos y la expresión de Dios mismo.</p>"
      },
      febrero13:{
        dia: "13 DE FEBRERO",
        titulo: "PERDÓNANOS NUESTRAS OFENSAS",
        cuerpo: "<p><i>Perdónanos nuestras deudas como también nosotros perdonamos a nuestros deudores.</i> (Mateo 6:12).</p><p>Esta cláusula es el punto decisivo de la oración. Esta es la llave estratégica.  Habiéndonos dicho qué es Dios, qué es el hombre, cómo trabaja el universo, cómo haremos nuestro propio trabajo, cuál es nuestro verdadero alimento o sustento y cómo podemos obtenerlo, ahora habla del perdón de nuestros pecados.</p><p>El perdón de los pecados es el problema central de la vida.  El pecado es un sentimiento de separación de Dios, y es la mayor tragedia de la experiencia humana.  Está, por  supuesto, enraizado en el egoísmo. Es esencialmente un intento de ganar algún supuesto bien al cual no tenemos derecho justamente.  Este es un sentido de existencia personal aislada y egoísta, mientras que la Verdad del Ser es que todo es Uno. Nuestros verdaderos seres son uno con Dios, indivisos de Él, expresando sus ideas, atestiguando Su naturaleza. Siendo todos uno con el gran Todo del cual somos espiritualmente una parte, resulta que somos uno con todos los hombres.</p><p>De hecho, el mal, el pecado y  la caída del hombre, son esencialmente un  intento para negar esta Verdad. Tratamos de vivir separados de Dios.  Actuamos como si pudiéramos tener planes, propósitos e intereses separados de Él. Todo esto, si fuera verdad, significaría que la existencia no es una y armoniosa, sino un caos de competencia y rivalidad.  Pero, por supuesto esto  no es verdad, y en eso reside el gozo de la vida.</p>"
      },
      febrero14:{
        dia: "14 DE FEBRERO",
        titulo: "ASÍ COMO NOSOTROS PERDONAMOS",
        cuerpo: "<p>Mientras repetimos la Gran Plegaria inteligentemente, somos de repente puestos al día y apretados como si estuviéramos en el tornillo de un banco, entonces tenemos que enfrentar este problema de la separación de Dios. Debemos extender el perdón a todos.</p><p>Observen que Jesús no dijo “Perdóname mis trasgresiones y yo trataré de perdonar a otros”. Él nos obliga a declarar que nosotros hemos perdonado en realidad y él hace depender nuestra demanda por nuestro perdón de esto. ¿Quién puede ser tan loco como para intentar buscar el Reino de Dios sin desear ser liberado de su propio sentimiento de culpa? Nosotros estamos  atrapados en la inescapable posición de que no podemos demandar nuestra propia liberación antes de que hayamos liberado a nuestro hermano.</p><p><i>Examíname, Oh Dios y conoce mi corazón; pruébame y conoce mis pensamientos…</i> (Salmo 139:23).</p>"
      },
      febrero15:{
        dia: "15 DE FEBRERO",
        titulo: "DEMOSTRANDO PERDÓN",
        cuerpo: "<p><i>Y como queréis que hagan los hombres con vosotros, así también haced vosotros con ellos.</i> (Lucas 6:31).</p><p>El perdón de otros es el vestíbulo del Cielo. Tienes  que deshacerte de todo resentimiento y condenación hacia otros, y también de la auto-condenación y remordimiento. Tienes que perdonarte a ti mismo, pero no puedes hacerlo hasta que no hayas perdonado a otros primero.</p><p>Por supuesto, lo  más fácil del mundo es perdonar a gente que no nos ha herido tanto. Pero lo que la Ley del Ser requiere, precisamente, es que perdonemos las cosas que son tan difíciles de perdonar, aunque al principio parezca imposible hacerlo. Pero el Padre Nuestro hace que nuestro único escape de la culpa y la limitación dependan justamente de esto.</p><p>Si tus oraciones no están siendo respondidas, examina tu consciencia y observa sin no hay alguna vieja circunstancia acerca de la cual todavía estás resentido. Busca y examina si no le estás realmente guardando rencor a algún individuo o grupo. Si es así, entonces tienes que hacer un acto de perdón y cuando esté hecho, probablemente lograrás hacer tu demostración. Si no puedes perdonar ahora, tendrás que esperar para tu demostración hasta que puedas y también posponer el rezo del Padre Nuestro.</p>"
      },
      febrero16:{
        dia: "16 DE FEBRERO",
        titulo: "LIBERTAD EN EL PERDÓN",
        cuerpo: "<p><i>Porque si perdonáis a los hombres sus ofensas, os perdonará también a vosotros vuestro Padre Celestial.</i>  (Mateo 6:14).</p><p>Liberar a otros significa liberarte a ti mismo, porque el resentimiento es realmente una forma de apego. Es una verdad cósmica, que se necesitan dos para hacer a un prisionero: el prisionero y el carcelero. Uno no puede ser prisionero por cuenta propia. El carcelero es tan prisionero como su oficio.  Cuando guardas resentimiento contra alguien, estás atado a esa persona por una cadena mental. Estás atado a lo que odias por un lazo cósmico. La persona por la cual sientas más desprecio, es la misma a la que estás uniéndote por un gancho  más fuerte que el acero. ¿Es eso lo que deseas? ¿Es esa la condición en la cual deseas seguir viviendo? Recuerda que perteneces a aquello a lo cual estás conectado en pensamiento, y en algún momento, si esta atadura continúa, el objeto de tu resentimiento será traído nuevamente a tu vida, quizás para obrar más estragos. Debes cortar toda atadura de este tipo mediante un claro acto de perdón, nadie puede proporcionarte tal cosa. Debes soltarlo y dejarlo ir. Por el perdón te pones en libertad, salvas tu alma. Y porque la ley del amor trabaja igualmente para uno y todos, ayudas a salvar su alma también.</p>"
      },
      febrero17:{
        dia: "17 DE FEBRERO",
        titulo: "CÓMO PERDONAR",
        cuerpo: "<p><i>Echa sobre Yo Soy tu carga, y él te sustentará…</i> (Salmo 55:22).</p><p>La técnica del perdón no es muy difícil cuando entiendes cómo usarla. La única cosa esencial es la buena voluntad de perdonar. Manifestando tu deseo de perdonar al ofensor, la mayor parte del trabajo ya está hecho.</p><p>El método de perdonar es el siguiente: Recógete internamente. Repite cualquier oración que te agrade, o lee un capitulo de la Biblia. Entonces, calmadamente di: “Yo plena y libremente perdono a “X” (menciona el nombre del ofensor), lo suelto y lo dejo ir. Desecho la carga. Él es libre ahora y yo soy libre también. La Verdad del Cristo nos ha hecho libres. Doy gracias a Dios.</p><p>De ninguna manera repitas este acto de perdón, porque hacerlo una segunda vez, sería repudiar tácitamente tu propio trabajo. Después, cada vez que la memoria del ofensor o la ofensa vengan a tu mente,  bendice al delincuente brevemente y rechaza el pensamiento. Haz esto todas las veces que el pensamiento vuelva. Encontrarás que todo rencor y resentimiento ha desaparecido y que ambos son libres con la perfecta libertad de los hijos de Dios. Tu perdón está completo.</p>"
      },
    };
  },
};
</script>
